<template>
  <div class="column q-gutter-y-md">
    <q-table flat wrap-cells
      :data="users"
      :columns="columns"
      row-key="id"
      :rows-per-page-options="rowsPerPageOptions"
      :pagination.sync="pagination"
      :loading="loading"
      :filter="filter"
      @request="onRequest"
      binary-state-sort>
      <q-tr slot="body" slot-scope="props" :props="props"
        @click.native="$router.push({ name: 'user-show', params: { id: props.row.id } })"
        class="cursor-pointer">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.name === 'region_id'">
          {{ col.value }}
        </q-td>
      </q-tr>
    </q-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'UsersList',

  data () {
    return {
      filter: '',
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      users: 'users/getUsers',
      filters: 'users/getFilters',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    pagination: {
      ...mapGetters({ get: 'users/getPagination' }),
      ...mapMutations({ set: 'users/setPagination' })
    },

    columns () {
      if (this.users.length === 0) return []
      else {
        const fields = ['display_name', 'roles', 'email', 'region']
        const sortable = ['email', 'region_id']

        return fields.map(key => {
          let label, align = 'left', formatFn = val => val

          if (key === 'roles') {
            label = this.$t(`users.fields.role.label`)
            formatFn = val => val.map(role => role.display_name).join(', ')
          } else if (key === 'region') {
            label = this.$t(`users.fields.region_id.label`)
            formatFn = val => val && this.$t(`regions.${val['CODEREG']}`)
          } else {
            label = this.$t(`users.fields.${key}.label`)
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: sortable.indexOf(key) >= 0
          }
        })
      }
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.$nextTick(() => {
          this.onRequest({ pagination: this.pagination })
        })
      },
      deep: true
    }
  },

  mounted () {
    if (this.filters) { this.onRequest({ pagination: this.pagination }) }
  },

  methods: {
    onRequest ({ pagination, filter }) {
      this.$store.commit('users/setPagination', pagination)

      let params = { ...pagination }
      this.filters.forEach(filter => { params[filter.id] = filter.value })

      this.loading = true
      this.$store.dispatch('users/fetchUsers', params)
        .finally(() => { this.loading = false })
    }
  }
}
</script>
